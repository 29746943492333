import React from "react"
import Layout from "@/components/layout"
import SeriosAcademyForm from "@/components/SeriosAcademyForm"

const SeriosAcademyPage = () => {
  return (
    <Layout>
      <SeriosAcademyForm />
    </Layout>
  );
};

export default SeriosAcademyPage;
